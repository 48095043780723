import ALink from '../ALink'
import backgroundImg from '../../assets/Subtract2.png';


const FailPage = () => {
  return (
    <main>
    <section className="main-section payment-success-wrap">
      <div className="payment-sucess-top">
        <h2 className='page-title-header' >결제가 실패하였습니다.</h2>
      </div>

      {/* information 영역 */}
      <div
      className="payment-success-information"
      >
        <div
        className="payment-success-info-top"
        >
          <p>다시 한번 확인하시고 결제 신청을 시도해 주세요.</p>


        </div>
      </div>
    <ALink type="blue" text="처음으로" address="/" />
    </section>
    
    
    <img className="background-img"  src={backgroundImg} alt="배경이미지" />


  </main>
  )
}

export default FailPage