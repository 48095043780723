 export const positionList: { ko: string; eng: string }[] = [
    {
      ko: '직군을 선택해주세요.',
      eng: "",
    },
    {
      ko: '소프트웨어 엔지니어',
      eng: "sw_eng",
    },
    {
      ko: '모바일 엔지니어 (iOS, Android)',
      eng: 'mobile',
    },
    {
      ko: '프론트엔드 엔지니어',
      eng: "frontend",
    },
    {
      ko: '백엔드 엔지니어',
      eng: "backend",
    },
    {
      ko: 'ML/AI 엔지니어',
      eng: "ml_ai"
    },
    {
      ko: '네트워크 엔지니어',
      eng: "network",
    },
    {
      ko: '데이터 엔지니어',
      eng: "data_eng",
    },
    {
      ko: '프로덕트/프로젝트 매니저',
      eng: "pm",
    },
    {
      ko: '서비스 기획자',
      eng: "planner",
    },
    {
      ko: '데이터 분석가',
      eng: "analyst",
    },
    {
      ko: '프로덕트 디자이너',
      eng: "designer",
    },
    {
      ko: '마케터',
      eng: "marketer",
    },
    {
      ko: '비즈니스',
      eng: "business",
    },
    {
      ko: '이외',
      eng: "other",
    }
  ];