

const BouncingDots: React.FC = () => {
  return (
    <div className="bouncing-dots">
    <div className="dot"></div>
    <div className="dot"></div>
    <div className="dot"></div>
  </div>
  );
};

export default BouncingDots;
