import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

interface fetchDataType{
    success: true,
    count: number,
    isFull: boolean ,
}

const Slogan = ({ type, comment }: { type: string; comment: string }) => {
  const [data, setData] = useState<fetchDataType>()
  const navigate = useNavigate();

  // 좌석수 fetch call function
  useEffect(  ()=> {
    const fetchSeatNumber = async ()=>{
      try{

        const response = await fetch("https://conference.puddingcamp.com/api/count");
        if(!response.ok){
          if(response.status === 500){
            throw new Error ('오프라인 참여자 수 조회 중 예기치 않은 오류가 발생했습니다.')
          }
          
          throw new Error('데이터를 가져오는 데 실패하였습니다.')
        }
        const data:fetchDataType = await response.json();
        setData(data)

      } catch(e){
        console.error(`데이터를 가져오는데, 실패하였습니다. ${e}`);
      }
    }
    fetchSeatNumber()
    
  },[])


  return (
    <section className="main-section main-slogan-wrap">
      <div className="main-slogan-top">
        <h2 className="page-title-header">{comment}</h2>
        <div className="main-slogan-svg-wrap">
          <svg
            width="493"
            height="111"
            viewBox="0 0 493 111"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M77.2216 0C81.7369 0 86.4365 1.16286 88.9706 2.64286V19.5571C86.2061 17.9714 82.7044 16.65 79.0185 16.65C73.3973 16.65 70.3564 19.7686 70.3564 23.9971C70.3564 28.2257 73.3973 31.2914 79.0185 31.2914C82.7044 31.2914 86.4365 30.0229 89.201 28.2257V44.4C86.6208 45.9857 81.783 47.2543 77.0373 47.2543C63.6756 47.2543 53.9077 37.2114 53.9077 23.6271C53.9077 10.0429 63.6295 0 77.2216 0Z"
              fill="#F5F5F5"
            />
            <path
              d="M114.634 0C127.166 0 136.98 10.3071 136.98 23.6271C136.98 36.9471 127.212 47.2543 114.634 47.2543C102.055 47.2543 92.2876 36.9471 92.2876 23.6271C92.2876 10.3071 102.102 0 114.634 0ZM114.634 31.08C118.043 31.08 120.808 27.9086 120.808 23.9971C120.808 20.0857 118.043 16.9143 114.634 16.9143C111.224 16.9143 108.46 20.0857 108.46 23.9971C108.46 27.9086 111.224 31.08 114.634 31.08Z"
              fill="#F5F5F5"
            />
            <path
              d="M140.989 0.687134H155.963L167.712 20.2971H167.804V0.687134H182.594V46.5671H167.62L155.871 26.9571H155.779V46.5671H140.989V0.687134Z"
              fill="#F5F5F5"
            />
            <path
              d="M187.524 0.687134H217.15V16.0157H204.019V18.6057H214.524V30.1286H204.019V46.5671H187.57V0.687134H187.524Z"
              fill="#F5F5F5"
            />
            <path
              d="M221.481 0.687134H250.278V16.0157H237.976V18.5H247.928V29.3886H237.976V31.9786H251.061V46.5671H221.481V0.687134Z"
              fill="#F5F5F5"
            />
            <path
              d="M255.162 0.687134H271.565C279.812 0.687134 291.008 3.01285 291.008 15.2757C291.008 20.2443 288.981 23.7857 285.986 26.1643L295.109 46.5671H277.831L271.841 31.5028H271.611V46.5671H255.162V0.687134ZM272.855 20.9843C275.435 20.9843 276.633 19.3986 276.633 17.4429C276.633 15.4871 275.435 13.9543 272.855 13.9543H271.657V20.9843H272.855Z"
              fill="#F5F5F5"
            />
            <path
              d="M296.86 0.687134H325.657V16.0157H313.355V18.5H323.307V29.3886H313.355V31.9786H326.44V46.5671H296.86V0.687134Z"
              fill="#F5F5F5"
            />
            <path
              d="M330.494 0.687134H345.468L357.217 20.2971H357.31V0.687134H372.1V46.5671H357.125L345.376 26.9571H345.284V46.5671H330.494V0.687134Z"
              fill="#F5F5F5"
            />
            <path
              d="M399.514 0C404.03 0 408.729 1.16286 411.263 2.64286V19.5571C408.499 17.9714 405.043 16.65 401.311 16.65C395.69 16.65 392.649 19.7686 392.649 23.9971C392.649 28.2257 395.69 31.2914 401.311 31.2914C404.997 31.2914 408.729 30.0229 411.494 28.2257V44.4C408.913 45.9857 404.076 47.2543 399.33 47.2543C385.968 47.2543 376.154 37.2114 376.154 23.6271C376.154 10.0429 385.876 0 399.468 0L399.514 0Z"
              fill="#F5F5F5"
            />
            <path
              d="M416.055 0.687134H444.852V16.0157H432.55V18.5H442.502V29.3886H432.55V31.9786H445.635V46.5671H416.055V0.687134Z"
              fill="#F5F5F5"
            />
            <path
              d="M4.65355 92.6585L0 68.1857L16.0801 64.1685L20.5033 87.3728C21.2865 91.6014 23.0374 94.2443 26.7694 93.3457C30.5015 92.4471 31.1005 89.54 30.225 85.0471L25.8019 61.7371L40.2693 58.1428L44.8768 82.3514C46.1669 89.2228 45.4758 93.9271 43.9553 97.31C41.3291 103.177 35.2933 107.089 28.6585 108.727C22.0237 110.366 15.2968 109.467 10.9658 105.661C8.52383 103.547 6.03579 99.9528 4.65355 92.6585Z"
              fill="#1A8DF7"
            />
            <path
              d="M56.0729 58.1428L70.909 60.4685L80.2621 81.7171H80.4004L82.7041 62.3185L97.3559 64.5914L91.9651 110.049L77.129 107.723L67.7759 86.4743H67.6376L65.3339 105.873L50.6821 103.6L56.0729 58.1428Z"
              fill="#68B7FF"
            />
            <path
              d="M123.481 61.9487C127.997 61.9487 132.696 63.1116 135.23 64.5916V81.5059C132.466 79.9202 128.964 78.5987 125.278 78.5987C119.657 78.5987 116.616 81.7173 116.616 85.9459C116.616 90.1744 119.657 93.2402 125.278 93.2402C128.964 93.2402 132.696 91.9716 135.461 90.1744V106.349C132.881 107.934 128.043 109.203 123.297 109.203C109.935 109.203 100.167 99.1602 100.167 85.5759C100.167 71.9916 109.889 61.9487 123.481 61.9487Z"
              fill="#F5F5F5"
            />
            <path
              d="M160.94 61.9487C173.472 61.9487 183.286 72.2559 183.286 85.5759C183.286 98.8959 173.518 109.203 160.94 109.203C148.361 109.203 138.593 98.843 138.593 85.5759C138.593 72.3087 148.361 61.9487 160.94 61.9487ZM160.94 93.0287C164.349 93.0287 167.114 89.8573 167.114 85.9459C167.114 82.0344 164.349 78.863 160.94 78.863C157.53 78.863 154.766 82.0344 154.766 85.9459C154.766 89.8573 157.53 93.0287 160.94 93.0287Z"
              fill="#F5F5F5"
            />
            <path
              d="M187.249 62.6359H202.223L213.972 82.2459H214.064V62.6359H228.854V108.516H213.88L202.131 88.9059H202.039V108.516H187.249V62.6359Z"
              fill="#F5F5F5"
            />
            <path
              d="M233.83 62.6359H263.456V77.9644H250.324V80.5544H260.829V92.0773H250.324V108.516H233.876V62.6359H233.83Z"
              fill="#F5F5F5"
            />
            <path
              d="M267.74 62.6359H296.536V77.9644H284.235V80.4487H294.187V91.3373H284.235V93.9273H297.32V108.516H267.74V62.6359Z"
              fill="#F5F5F5"
            />
            <path
              d="M301.421 62.6359H317.824C326.071 62.6359 337.267 64.9616 337.267 77.2244C337.267 82.193 335.24 85.7344 332.245 88.113L341.368 108.516H324.09L318.146 93.4516H317.916V108.516H301.467V62.6359H301.421ZM319.114 82.933C321.694 82.933 322.892 81.3473 322.892 79.3916C322.892 77.4359 321.694 75.903 319.114 75.903H317.916V82.933H319.114Z"
              fill="#F5F5F5"
            />
            <path
              d="M343.12 62.6359H371.916V77.9644H359.614V80.4487H369.567V91.3373H359.614V93.9273H372.7V108.516H343.12V62.6359Z"
              fill="#F5F5F5"
            />
            <path
              d="M376.798 62.6359H391.773L403.522 82.2459H403.614V62.6359H418.404V108.516H403.43L391.681 88.9059H391.588V108.516H376.798V62.6359Z"
              fill="#F5F5F5"
            />
            <path
              d="M445.774 61.9487C450.289 61.9487 454.989 63.1116 457.523 64.5916V81.5059C454.759 79.9202 451.303 78.5987 447.571 78.5987C441.95 78.5987 438.909 81.7173 438.909 85.9459C438.909 90.1744 441.95 93.2402 447.571 93.2402C451.257 93.2402 454.989 91.9716 457.753 90.1744V106.349C455.173 107.934 450.335 109.203 445.59 109.203C432.228 109.203 422.414 99.1602 422.414 85.5759C422.414 71.9916 432.136 61.9487 445.728 61.9487H445.774Z"
              fill="#F5F5F5"
            />
            <path
              d="M462.313 62.6359H491.11V77.9644H478.808V80.4487H488.76V91.3373H478.808V93.9273H491.894V108.516H462.313V62.6359Z"
              fill="#F5F5F5"
            />
          </svg>
        </div>
        <div className="main-slogan-locationTime">
          <p>
            <span>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.50016 9.16671C7.50016 9.82975 7.76355 10.4656 8.2324 10.9345C8.70124 11.4033 9.33712 11.6667 10.0002 11.6667C10.6632 11.6667 11.2991 11.4033 11.7679 10.9345C12.2368 10.4656 12.5002 9.82975 12.5002 9.16671C12.5002 8.50367 12.2368 7.86778 11.7679 7.39894C11.2991 6.9301 10.6632 6.66671 10.0002 6.66671C9.33712 6.66671 8.70124 6.9301 8.2324 7.39894C7.76355 7.86778 7.50016 8.50367 7.50016 9.16671Z"
                  stroke="white"
                  strokeOpacity="0.6"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.7143 13.8808L11.1785 17.4167C10.866 17.7289 10.4423 17.9042 10.0006 17.9042C9.55885 17.9042 9.13518 17.7289 8.82267 17.4167L5.286 13.8808C4.35368 12.9485 3.71878 11.7606 3.46157 10.4674C3.20437 9.17419 3.33641 7.83376 3.841 6.61561C4.3456 5.39745 5.20008 4.35628 6.2964 3.62376C7.39272 2.89123 8.68164 2.50024 10.0002 2.50024C11.3187 2.50024 12.6076 2.89123 13.7039 3.62376C14.8003 4.35628 15.6547 5.39745 16.1593 6.61561C16.6639 7.83376 16.796 9.17419 16.5388 10.4674C16.2816 11.7606 15.6466 12.9485 14.7143 13.8808Z"
                  stroke="white"
                  strokeOpacity="0.6"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <a 
            href="https://naver.me/FZJ1r6LZ"
            target="_blank"
            >
              서울 강남구 역삼로 172 마루 360 (B1 층)
            </a>
          </p>
          <p>
            <span>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.9167 10H10V5.83333M2.5 10C2.5 10.9849 2.69399 11.9602 3.0709 12.8701C3.44781 13.7801 4.00026 14.6069 4.6967 15.3033C5.39314 15.9997 6.21993 16.5522 7.12987 16.9291C8.03982 17.306 9.01509 17.5 10 17.5C10.9849 17.5 11.9602 17.306 12.8701 16.9291C13.7801 16.5522 14.6069 15.9997 15.3033 15.3033C15.9997 14.6069 16.5522 13.7801 16.9291 12.8701C17.306 11.9602 17.5 10.9849 17.5 10C17.5 9.01509 17.306 8.03982 16.9291 7.12987C16.5522 6.21993 15.9997 5.39314 15.3033 4.6967C14.6069 4.00026 13.7801 3.44781 12.8701 3.0709C11.9602 2.69399 10.9849 2.5 10 2.5C9.01509 2.5 8.03982 2.69399 7.12987 3.0709C6.21993 3.44781 5.39314 4.00026 4.6967 4.6967C4.00026 5.39314 3.44781 6.21993 3.0709 7.12987C2.69399 8.03982 2.5 9.01509 2.5 10Z"
                  stroke="white"
                  strokeOpacity="0.6"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            9월 28일(토), 13:00 ~ 17:00
          </p>
        </div>
      </div>

      {
        type === "main" &&
          <div
          className="main-buttons-wrap"
          >

            <Link 
            to='/online'
            className="main-free-link"
            >
              온라인 참여(무료)
            </Link>
            <button
            className="offline-attend-btn"
            disabled={data?.isFull}
            onClick={()=> !data?.isFull && navigate('/payment')}
            >
            {data?.isFull ? "마감" : "오프라인 참여"}
            </button>
            


          </div>   
      }


    </section>
  );
};

export default Slogan;
