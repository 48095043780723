import ALink from './ALink'


const Error500 = () => {
  return (
    <main>
      <section
      className='error-wrap'
      >


      <h2 className='page-title-header' >서버 오류가 발생했어요.</h2>
      <div
        className='error-message-wrap'
      >
        <p>푸딩캠프 이용에 불편을 드려 죄송합니다.</p>
        <p>서비스 오류 등 비정상 동작으로 장애가 발생하여 서비스 이용이 원활치 않습니다. 이 문제는 일시적으로 발생할 가능성이 크나 지속적으로 발생한다면 고객센터에 문의해주시기 바랍니다.</p>
        <p>문제 상황은 푸딩캠프 개발팀에 전달되었으며, 일시적인 문제가 아닌 경우 조속히 파악하여 조치하겠습니다.</p>
        <p>푸딩캠프 드림.</p>
      </div>

      <ALink type='blue' text='컨퍼런스로 돌아가기' address='/' />

      </section>
    </main>
  )
}

export default Error500