import ALink from './ALink'

const ErrorPage = () => {
  return (
    <main>
      <section
      className='error-wrap'
      >


      <h2 className='page-title-header' >페이지가 존재하지 않아요.</h2>
      <div
        className='error-message-wrap'
      >
        <p>현재 들어오신 페이지는 존재하지 않는 페이지입니다.</p>
        <p>어디로 방문하셨는지 다시 한번 확인해 보시고 이용해주시면 고맙겠습니다.</p>
        <p>푸딩캠프 드림.</p>
      </div>

      <ALink type='blue' text='컨퍼런스로 돌아가기' address='/' />

      </section>
    </main>
  )
}

export default ErrorPage