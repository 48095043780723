import { useSearchParams } from "react-router-dom";
import backgroundImg from '../../assets/Subtract2.png';
import { formatCurrency } from "../../utils/currencyFn";
import ALink from "../ALink";
import './Paymnet.css';


const SuccessPage = () => {
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("orderId");
  const amount = searchParams.get("amount");
  let price = Number(amount) || 0

  return (
    <main>
      <section className="main-section payment-success-wrap">
        <div className="payment-sucess-top">
          <h2 className="page-title-header" >결제완료되었습니다.</h2>
          <p>작성하신 메일로 결제내역이 전송되었어요.</p>
        </div>

        {/* information 영역 */}
        <div
        className="payment-success-information"
        >
          <div
          className="payment-success-info-top"
          >
            <p
              className="payment-order-id"
            >주문 번호 : {orderId}</p>
            <p>{formatCurrency(price)()} 원</p>

          </div>
        </div>
      <ALink type="blue" text="처음으로" address="/" />
      </section>
      
      
      <img className="background-img"  src={backgroundImg} alt="배경이미지" />


    </main>
  );
};

export default SuccessPage;
