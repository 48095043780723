
const Footer = () => {
  return (
    <footer
      className='footer-wrap'
    >
      <div
        className='footer-inner-wrap'
      >
        <ul>
          <li>
            <a 
            target="_black"
            href="https://puddingcamp.com/notice"> 알립니다</a>
          </li>
          <li>
            <a 
            target="_black"
            href="https://puddingcamp.com/about/"> 푸딩캠프소개</a>
          </li>
          <li>
            <a 
            target="_black"
            href="https://s3.ap-northeast-2.amazonaws.com/pudding.camp/static/pages/terms.html "> 이용약관</a>
          </li>
          <li>
            <a 
            target="_black"
            href="https://s3.ap-northeast-2.amazonaws.com/pudding.camp/static/pages/privacy.html" >개인정보 처리방침</a>
          </li>
          <li
            className='footer-question'
          >
            <a href="/ask"> 문의하기</a>
          </li>
        </ul>
        <p>© 2024 by Real World Pudding Inc. All rights reserved.</p>
      </div>
    </footer>
  )
  }

export default Footer