import React, { useEffect, useRef, useState } from 'react'
import {
  loadTossPayments,
  TossPaymentsWidgets,

} from "@tosspayments/tosspayments-sdk";


const clientKey = import.meta.env.VITE_API_KEY;

interface PersonDataType {
  customer_email: string;
  customer_job: string;
  customer_mobile: string;
  customer_name: string;
  product_sku: string;
  quantity: number;
  result: string;
  uid: string;
  price : number
}

interface TossPaymentProp {
  personData: PersonDataType;
  isClicked: boolean;
  setIsClicked: React.Dispatch<React.SetStateAction<boolean>>;
};


const TossPayment:React.FC<TossPaymentProp> = ({personData, isClicked, setIsClicked}) => {
  const pageRef = useRef<HTMLDivElement | null>(null);

  // toss payment statements
  const [ready, setReady] = useState(false);
  const [widgets, setWidgets] = useState<TossPaymentsWidgets | null>(null);



  const amount ={
    currency: "KRW",
    value: personData?.price,
  };
  let customerKey = personData?.uid


  useEffect(() => {
    if (widgets == null) {
      return;
    }
  
  }, [widgets]);

  // toss payment set up Key and user
  useEffect(() => {
    async function fetchPaymentWidgets() {
      try {
        // ------  SDK 초기화 ------
        // @docs https://docs.tosspayments.com/sdk/v2/js#토스페이먼츠-초기화
        const tossPayments = await loadTossPayments(clientKey);

        // 회원 결제
        // @docs https://docs.tosspayments.com/sdk/v2/js#tosspaymentswidgets
        const widgets = tossPayments.widgets({
          customerKey,
        });

        // 비회원 결제
        // const widgets = tossPayments.widgets({ customerKey: ANONYMOUS });


        setWidgets(widgets);
      } catch (error) {
        console.error("Error fetching payment widget:", error);
      }
    }

    fetchPaymentWidgets();
  }, [clientKey,customerKey,]);



  useEffect(() => {
    async function renderPaymentWidgets() {
      if (widgets == null) {
        return;
      }
      await widgets.setAmount(amount);
  
      await Promise.all([
  
        widgets.renderPaymentMethods({
          selector: "#payment-method",
          variantKey: "DEFAULT",
        }),
  
        // widgets.renderAgreement({
        //   selector: "#agreement",
        //   variantKey: "AGREEMENT",
        // }),
      ]);
  
      setReady(true);
    }


    renderPaymentWidgets();
  }, [widgets]);



const handleCancelTossPayment= () =>{
  setIsClicked(false);
  window.scrollTo(0, 0);
  window.location.reload();
}


  return (


    <div className={`${isClicked === true ?'wrapper' : 'wrapper-gone'}`}>
    <div className="box_section">
      {/* 결제 UI */}

      {/* toss 결제 위젯 감치는 역할 CSS display none으로 처리 */}
      {/* widget을 최기화 하는 방법으로 몰라 위와 같이 처리 */}
      <button
        className='toss-payment-cancel'
        onClick={handleCancelTossPayment}
      >
        <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18 6L6 18M6 6L18 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>

        {

          isClicked && (
            <div 
                id="payment-method" 
                ref={pageRef}
              />

          )

        }
      

      {/* 결제하기 버튼 */}
      <button
        className="toss-button"
        // 결제 버튼 활성화
        // 최초 Toss 샘플 및 샌드박스에 있는 방식 그대로 사용 ver2
        disabled={!ready}

        onClick={async () => {
          try {
            // 결제를 요청하기 전에 orderId, amount를 서버에 저장하세요.
            // 결제 과정에서 악의적으로 결제 금액이 바뀌는 것을 확인하는 용도입니다.
            await widgets?.requestPayment({
              orderId: personData.uid,
              orderName: personData.product_sku,
              successUrl: window.location.origin + "/payment/request-success",
              failUrl: window.location.origin + "/payment/request-fail",
              customerEmail: personData.customer_email,
              customerName: personData.customer_name,
              customerMobilePhone: personData.customer_mobile,
            });
          } catch (error) {
            // 에러 처리하기
            console.error(error);
            window.alert(`에러 발생  ${error}`)
            // setWidgets(null)
            // setIsClicked(false)
          }
        }}
      >
        결제
      </button>
    </div>
  </div>
  );

}

export default TossPayment