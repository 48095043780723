import { useSearchParams } from "react-router-dom";
import backgroundImg from '../../assets/Subtract2.png';
import ALink from "../ALink";
import './Free.css'

const FreeSuccess = () => {

  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const uid = searchParams.get("uid");

  return (
    <main>
      <section className="main-section online-success-wrap">
        <div className="online-sucess-top">
          <h2>참석 접수가 완료되었습니다.</h2>
          <p>{email || "-"} 으로 결제내역이 전송되었어요.</p>
        </div>

        {/* information 영역 */}
        <div
        className="online-success-information"
        >
          <div
          className="online-success-info-top"
          >
            <p>온라인 참석을 환영합니다.</p>
            <p>금액 :  무료</p>
            <p>참석자 번호 : {uid || '-'}</p>
          </div>
        </div>
      <ALink type="blue" text="처음으로" address="/" />
      </section>
      
      
      <img className="background-img"  src={backgroundImg} alt="배경이미지" />


    </main>
  );
};



export default FreeSuccess