import { Link } from 'react-router-dom'

const ALink = ({type, text, address}:{type: string, text : string, address:string}) => {
  return (

    <Link
    to={address}
    className={`${type === 'blue' ? 'Alink-button' : "Alink-gray"}`}
    preventScrollReset={false}
    >
      {text}
    </Link>

  )
}

export default ALink