import './IntrouduceSpeaker.css';
import pop from '../../assets/Property_pop.png';
import paul from '../../assets/Property_paul.png';
import chloe from '../../assets/Property_chloe.png';
import rabbit from '../../assets/Property_rabbit.png';
import cheeze from '../../assets/Property_cheeze.png';
import kwaan from '../../assets/Property_Kwaan.png';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const InstroduceSpeaker = () => {
  const location = useLocation();

  useEffect(() => {
    // Scroll to the element with the matching ID
    const hash = location.hash;
    if (hash) {
      const element = document.getElementById(hash.substring(1)); // Remove '#' from hash
      if (element) {
        element.scrollIntoView({ 
          behavior: 'smooth',
          block:"center"
          });
      }
    }
  }, [location]);

  return (
    <main
      id='introduce-main'
    >
      <section className="main-section introduce-speakers-wrap">
        <div className="introduce-speakers-top">
          <h2 className="page-title-header" >연사 소개 페이지</h2>
          <p
            className='introduce-speakers-text'
          >
            나의 학습과 성장을 도와줄<br />
            세션 발표자를 만나보세요.
          </p>
        </div>
      </section>


      {/* 콘텐츠 내용 body */}
      <article
        className='introduce-speakers-body'
      >
        <ul>
          <li id='popenaga' >
            <img src={pop} alt="김민혜" />
            <div
              className='introduce-speaker-info'
            >
              {/* top */}
              <dl>
                <dt>김민혜</dt>
                <dd>popenaga</dd>
                <dd>개발팀장, 한빛앤</dd>
              </dl>

              {/* bottom */}
              <p>
              게임 개발자로 시작해 웹/모바일 개발자로 전환, 카카오에서 대용량 트래픽을 다루며 경험을 쌓았습니다. 스타트업 창업과 Meta에서의 빅테크 경험을 통해 성장했고, AI 스타트업을 거쳐 한빛앤 개발팀을 이끌며 새로운 도전을 시작했습니다. 언제나 시대의 흐름에 맞추어 커리어를 전환하면서 성장했고, 이제는 경험을 바탕으로 개발자이자, 나와 내 주변을 더 나은 환경으로 만드는 점화제가 되고자합니다.
              </p>
            </div>
          </li>

          <li id='paul'>
            <img src={paul} alt="정성영" />
            <div
              className='introduce-speaker-info'
            >
              {/* top */}
              <dl>
                <dt>정성영</dt>
                <dd>Paul</dd>
                <dd>CEO, 마켓핏랩</dd>
              </dl>

              {/* bottom */}
              <p>
              19년차 PM으로, 현재 그로스 컨설팅 에이전시인 마켓핏랩의 대표이자 현대자동차와 엘지전자의 컨설턴트로 활동하고 있습니다. 건강관리 앱 Noom의 그로스 전략을 이끌며 글로벌 1위 앱으로 성장시켰고, 이후 한국신용데이터, Class101에서 그로스 리드를 맡았습니다. 삼성전자 C-lab, 카카오벤처스, 블루포인트파트너스에서 그로스 컨설턴트로서 초기 기업의 성장을 도왔으며, 그로스 컨설팅 펌 마켓핏랩을 세워 국내 업계의 그로스 전략 도입을 대중화하는 데 힘쓰고 있습니다.
              </p>
            </div>
          </li>

          <li id='chloe'>
            <img src={chloe} alt="조단원" />
            <div
              className='introduce-speaker-info'
            >
              {/* top */}
              <dl>
                <dt>조단원</dt>
                <dd>Chloé</dd>
                <dd>프론트엔드 개발자, 산타파이브</dd>
              </dl>

              {/* bottom */}
              <p>
              회사 경력은 1년 남짓이지만, 사이드 프로젝트는 4년차입니다. "산타파이브" 팀에서 크리스마스 롤링페이퍼 "내 트리를 꾸며줘!"를 개발 및 운영하며, 프론트엔드뿐 아니라 제품과 고객을 위한 다양한 역할을 맡고 있습니다. "팀을 팀으로 존재하게 하는 팀원"이라는 자부심을 느끼며, 다정함이 세상을 바꾼다고 믿고, 사람들의 일상에 말로, 글로 그리고 코드로 작은 변화를 불러일으키고 싶습니다.
              </p>
            </div>
          </li>

          {/* 다음줄  */}
          <li id='rabbit' >
            <img src={rabbit} alt="김태희" />
            <div
              className='introduce-speaker-info'
            >
              {/* top */}
              <dl>
                <dt>김태희</dt>
                <dd>탐정토끼</dd>
                <dd>웹 풀스택 개발자, 셀파스</dd>
              </dl>

              {/* bottom */}
              <p>
              2019년에 삶을 풍요롭게 하는 코칭을 시작하고, 수많은 분을 도우며 코치로 일해왔습니다. 2021년부터 SAP Labs Korea와 Fromm을 거쳐 현재 Sherpas에서 클로저 풀스택 개발자로 웹 개발을 하고 있습니다. 특히 함수형 프로그래밍, 접근성, 프론트엔드 테스트를 연구하며 다양한 밋업과 행사에서 발표와 강연을 하고 있습니다.
              </p>
            </div>
          </li>

          <li id='cheeze'>
            <img src={cheeze} alt="서지연" />
            <div
              className='introduce-speaker-info'
            >
              {/* top */}
              <dl>
                <dt>서지연</dt>
                <dd>치즈</dd>
                <dd>Data AI 팀 리더, 비마이프렌즈</dd>
              </dl>

              {/* bottom */}
              <p>
              카카오, 네이버, 페이스북 등에서 뉴스, 커머스, AI, NFT 등 다양한 도메인을 탐구하며, 세상을 즐겁게 할 제품 만들기에 열정을 쏟고 있습니다. 함께 성장하는 개발 문화를 중요하게 생각하며, 데뷰와 인프콘에서 기술 발표를 하고 팟캐스트 '나는 프로그래머다'의 공동 MC로 활동했습니다. '치즈'라는 이름으로 개발자 수다에 자주 참여합니다.
              </p>
            </div>
          </li>
          <li id='kwaan'>
            <img src={kwaan} alt="권준호" />
            <div
              className='introduce-speaker-info'
            >
              {/* top */}
              <dl>
                <dt>권준호</dt>
                <dd>Kwaan</dd>
                <dd>대표파트너, PMF파트너스</dd>
              </dl>

              {/* bottom */}
              <p>
              20여년 경력의 대부분을 제품에 관련한 직업으로 살아왔습니다. 게임 회사에서 사내 신규 사업을 기획해 창업을 했습니다. 이후 삼성페이 Product Manager로 제품을 산출하면서 PM으로서 성장해왔습니다. 또한 제품을 중심으로 보는 초기투자자(액셀러레이터 / 투자 조합)로서 활동하고 있습니다.
              </p>
            </div>
          </li>
        </ul>

      </article>


    </main>
  )
}

export default InstroduceSpeaker