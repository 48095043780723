import { useNavigate, useSearchParams } from 'react-router-dom';
import backgroundImg from '../../assets/Subtract2.png';
import BouncingDots from "./BouncingDots";
import { useState } from "react";

const MAIN_SERVER_URL = 'https://puddingcamp.com';

const PAYMENT_STATUS_MAP = new Map([
  ['PAID', '/payment/success'],
  ['DONE', '/payment/success'],
  ['PAY_PROCESS_CANCELED', '/payment/fail'],
  ['PAY_PROCESS_ABORTED', '/payment/fail'],
  ['REJECT_CARD_COMPANY', '/payment/fail'],
  ['PAY_PROCESS_FAILED', '/payment/fail'],
  ['FAILED', '/payment/fail'],
]);


const SuccessPage = () => {
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("orderId");
  const naigate = useNavigate();
  const [ isFetching, setIsFetching ] = useState(true);

  const tId = setInterval(async () => {
    const res = await fetch(
      `${MAIN_SERVER_URL}/orders/@check/${orderId}`,
      {
        method: 'POST',
        credentials: "same-origin" as RequestCredentials,
        headers: {
          'Content-Type': 'application/json',
        },
        
      }
    );
    const data = await res.json();
    const urlPath = PAYMENT_STATUS_MAP.get(data.payment_status);
    if(!urlPath) return;

    clearInterval(tId);
    setIsFetching(false);
    naigate(`${urlPath}?orderId=${data.uid}&amount=${data.price}`);
    return;
}, 1000);

  return (
    <main>
      <section className="main-section payment-success-wrap">
        <div className="payment-sucess-top">
          <h2 className="page-title-header" >결제 처리를 요청하였습니다.</h2>
          
            {
            isFetching && <BouncingDots />
            }
          <div
            className="payment-success-information"
          >
            <p>이 페이지를 <u>닫지 마시고 잠시 기다</u>려주세요.</p>
            <p>결제가 완료되면 자동으로 결제 완료 페이지로 이동합니다.</p>
          </div>
        </div>

      </section>
      
      
      <img className="background-img"  src={backgroundImg} alt="배경이미지" />



    </main>
  );
};

export default SuccessPage;
