import { useState } from 'react'
import '../../App.css';
import ALink from '../ALink';

import pop from '../../assets/pudding2-pop.png';
import paul from '../../assets/pudding2-jeung.png';
import chloe from '../../assets/pudding2-chloe.png';
import rabbit from '../../assets/pudding2-rabbit.png';
import cheeze from '../../assets/pudding2-cheeze.png';
import kwaan from '../../assets/pudding2-Kwaan.png';
import { Link } from 'react-router-dom';


const Speaker = () => {
  const [tab, setTab] =  useState(0);


  return (
    <section
      className='main-section main-speaker-wrap'
    >

      <h2>SESSION</h2>
      <p
        className='main-speaker-text'
      >
      학습이 이뤄지고 있는지 확신이 안 서나요? 사수가 없나요?<br />
      효과적이고 효율적으로 학습하며 성장하는 자기만의 학습과 성장 체계를 만들 수 있습니다.<br />
      연사자들의 각양각색 학습과 성장 스토리로 여러분의 학습과 성장을 키워보세요.<br />
      </p>

      {/* <ALink  type='gray' text="전체 스케줄 보러가기"  address='#'/> */}
      <div
        className='main-speaker-inner-wrap'
      >
        <div
          className='main-speaker-head'
        >
          <button
          className={`main-speaker-tab ${tab === 0 ? 'main-speacker-tab-actived' : ""}`}
            onClick={()=>setTab(0)}
          >성장홀</button>
          <button
            className={`main-speaker-tab ${tab === 1 ? 'main-speacker-tab-actived' : ""}`}

            onClick={()=>setTab(1)}
          >도전홀</button>
        </div>


        {/* 성장홀 UI 영역 */}
        <ul
          className={`main-speaker-ul  ${tab === 0 ? 'main-speaker-growth' : ""}`}
        >
          <li >
            <Link to="/speakers#popenaga">
            <div
              className='main-speaker-card-top'
            >
              <p>13:10 ~ 13:50</p>
              <p>커리어 결정의 순간들</p>

            </div>
            <div
              className='main-speaker-nanme'
            >
              <p>김민혜</p>
              <p>popenaga</p>
              <p>
                <span>개발팀장,&nbsp;</span>
                <span>한빛앤</span>
              </p>
            </div>
            <img src={pop} alt="김민혜" />
            </Link>
          </li>

          <li >
            <Link to="/speakers#paul">
            <div
              className='main-speaker-card-top'
            >
              <p>14:00 ~ 14:40</p>
              <p>
                주니어 PM을 위한<br />
                근거기반의 기능/실험<br />
                기획서 작성법
              </p>

            </div>
            <div
              className='main-speaker-nanme'
            >
              <p>정성영</p>
              <p>Paul</p>
              <p>
                <span>CEO,&nbsp;</span> 
                <span>마켓핏랩</span>
              </p>
            </div>
            <img src={paul} alt="정성영" />
            </Link>
          </li>

          <li >
            <Link to="/speakers#chloe">
            <div
              className='main-speaker-card-top'
            >
              <p>14:50 ~ 15:30</p>
              <p>
                사이드 프로젝트가<br /> 너를 성장케 하리라<br />
                <span className='main-speaker-card-text'>(<u>예상치 못한</u> 방향으로)</span>
              </p>

            </div>
            <div
              className='main-speaker-nanme'
            >
              <p>조단원</p>
              <p>Chloé</p>
              <p>
                <span>프론트엔드 개발자,&nbsp;</span>
                <span>산타파이브</span>
              </p>
            </div>
            <img src={chloe} alt="조단원" />
            </Link>
          </li>

        </ul>



        {/* 도전홀 items 영역 */}
        <ul
          className={`main-speaker-ul  ${tab === 1? 'main-speaker-challenge' : ""}`}
        >
          <li >
            <Link to='/speakers#rabbit' >
            <div
              className='main-speaker-card-top'
            >
              <p>13:10 ~ 13:50</p>
              <p>도움을 주고 받으면서<br />배우고 성장하기</p>
            </div>
            <div
              className='main-speaker-nanme'
            >
              <p>김태희</p>
              <p>탐정토끼</p>
              <p>
                <span>웹 풀스택 개발자,&nbsp;</span>
                <span>셀파스</span>
              </p>
            </div>
            <img src={rabbit} alt="김태희" />
            </Link>
          </li>

          <li>
            <Link to='/speakers#cheeze'>
            <div
              className='main-speaker-card-top'
            >
              <p>14:00 ~ 14:40</p>
              <p>개발자, 개발 리더,<br />그리고 프로덕트<br />오너로!</p>
            </div>
            <div
              className='main-speaker-nanme'
            >
              <p>서지연</p>
              <p>치즈</p>
              <p>
                <span>Data AI 팀 리더,&nbsp;</span>
                <span>비마이프렌즈</span>
              </p>
            </div>
            <img src={cheeze} alt="서지연" />
            </Link>
          </li>

          <li>
            <Link to='/speakers#kwaan' >
            <div
              className='main-speaker-card-top'
            >
              <p>14:50 ~ 15:30</p>
              <p>입문하는 PM, 성장하는 PM</p>
            </div>
            <div
              className='main-speaker-nanme'
            >
              <p>권준호</p>
              <p>Kwaan</p>
              <p>
                <span>대표파트너,&nbsp;</span>
                <span>PMF파트너스</span>
              </p>
            </div>
            <img src={kwaan} alt="권준호" />
            </Link>
          </li>


        </ul>

        <ALink type="gray" text="전체 스케줄 보러가기" address='/schedule' />
      </div>

    </section>
  )
}

export default Speaker