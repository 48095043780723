
const Event = () => {
  return (
    <section className="main-section  main-session-intro">
      <p className="main-seaction-title">EVENT</p>

      <p className="main-session-text main-section-each-title">
        즐거운 행사가 될 수 있도록
        <br />
        이벤트와 간식을 준비했답니다.
      </p>
      {/* <p>미리 확인하시고 하나도 놓치지 마세요!</p> */}

      <div className="main-event-boards-wrap">
        <dl>
          <dt>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <g clipPath="url(#clip0_243_4555)">
                <path
                  d="M15.2654 7.50815L11.3103 6.04415L9.83339 1.20371C9.80549 1.11279 9.74916 1.03322 9.67268 0.976686C9.5962 0.920155 9.50361 0.889648 9.4085 0.889648C9.3134 0.889648 9.2208 0.920155 9.14432 0.976686C9.06784 1.03322 9.01152 1.11279 8.98361 1.20371L7.50717 6.04415L3.55161 7.50815C3.46641 7.53964 3.3929 7.59648 3.34098 7.67101C3.28906 7.74555 3.26123 7.83421 3.26123 7.92504C3.26123 8.01588 3.28906 8.10453 3.34098 8.17907C3.3929 8.25361 3.46641 8.31044 3.55161 8.34193L7.50495 9.80549L8.98228 14.7935C9.00951 14.8854 9.06574 14.9661 9.14259 15.0235C9.21944 15.0808 9.31278 15.1118 9.40867 15.1118C9.50456 15.1117 9.59787 15.0806 9.67466 15.0232C9.75145 14.9658 9.8076 14.885 9.83473 14.793L11.3121 9.80504L15.2658 8.34149C15.3513 8.31025 15.425 8.25351 15.4771 8.17895C15.5292 8.10439 15.5571 8.01562 15.5571 7.92466C15.557 7.83371 15.529 7.74496 15.4768 7.67046C15.4247 7.59596 15.3509 7.53929 15.2654 7.50815Z"
                  fill="#FFAC33"
                />
                <path
                  d="M6.3765 12.3973L5.34805 12.0169L4.94805 10.5502C4.92298 10.4557 4.86737 10.3721 4.78988 10.3124C4.71238 10.2528 4.61735 10.2204 4.51956 10.2204C4.42177 10.2203 4.3267 10.2526 4.24915 10.3122C4.17159 10.3717 4.11589 10.4553 4.09072 10.5498L3.69072 12.0164L2.66272 12.3969C2.57752 12.4284 2.50401 12.4852 2.45209 12.5597C2.40017 12.6343 2.37234 12.7229 2.37234 12.8138C2.37234 12.9046 2.40017 12.9932 2.45209 13.0678C2.50401 13.1423 2.57752 13.1992 2.66272 13.2306L3.68539 13.6098L4.0885 15.2195C4.11263 15.3156 4.16818 15.4009 4.24632 15.4618C4.32446 15.5227 4.42071 15.5558 4.51978 15.5557C4.61886 15.5557 4.71507 15.5225 4.79315 15.4615C4.87122 15.4005 4.92668 15.3152 4.95072 15.2191L5.35383 13.6093L6.3765 13.2302C6.46199 13.1991 6.53584 13.1425 6.58803 13.068C6.64022 12.9935 6.66821 12.9047 6.66821 12.8138C6.66821 12.7228 6.64022 12.634 6.58803 12.5595C6.53584 12.485 6.46199 12.4284 6.3765 12.3973ZM4.4485 2.76931L3.39783 2.38042L3.0085 1.32931C2.9772 1.24392 2.92044 1.17019 2.84588 1.1181C2.77132 1.06602 2.68256 1.03809 2.59161 1.03809C2.50066 1.03809 2.4119 1.06602 2.33734 1.1181C2.26278 1.17019 2.20602 1.24392 2.17472 1.32931L1.78583 2.38042L0.73472 2.76931C0.649516 2.8008 0.576006 2.85764 0.524087 2.93217C0.472169 3.00671 0.444336 3.09537 0.444336 3.1862C0.444336 3.27704 0.472169 3.36569 0.524087 3.44023C0.576006 3.51477 0.649516 3.5716 0.73472 3.60309L1.78583 3.99198L2.17472 5.04309C2.20621 5.1283 2.26305 5.20181 2.33758 5.25372C2.41212 5.30564 2.50077 5.33348 2.59161 5.33348C2.68245 5.33348 2.7711 5.30564 2.84564 5.25372C2.92017 5.20181 2.97701 5.1283 3.0085 5.04309L3.39739 3.99198L4.4485 3.60309C4.5337 3.5716 4.60721 3.51477 4.65913 3.44023C4.71105 3.36569 4.73888 3.27704 4.73888 3.1862C4.73888 3.09537 4.71105 3.00671 4.65913 2.93217C4.60721 2.85764 4.5337 2.8008 4.4485 2.76931Z"
                  fill="#FFCC4D"
                />
              </g>
              <defs>
                <clipPath id="clip0_243_4555">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </dt>
          <dd>
            파트너 경험 이벤트<br />
            놓치치 마세요.
            <br /> 당신이 주인공
          </dd>
        </dl>
        <dl>
          <dt>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <g clipPath="url(#clip0_243_4556)">
                <path
                  d="M10.5117 10.3539L5.64551 5.48772L9.81706 2.01172L13.9882 6.18283L10.5117 10.3539Z"
                  fill="#BE1931"
                />
                <path
                  d="M15.3775 6.18291C15.1951 6.3656 14.9784 6.51054 14.7399 6.60942C14.5014 6.70831 14.2457 6.75921 13.9875 6.75921C13.7294 6.75921 13.4737 6.70831 13.2352 6.60942C12.9967 6.51054 12.78 6.3656 12.5975 6.18291L9.81666 3.40247C9.46235 3.03075 9.26745 2.53523 9.27358 2.02175C9.27971 1.50826 9.4864 1.01753 9.84948 0.65439C10.2126 0.291247 10.7033 0.0844836 11.2167 0.0782692C11.7302 0.0720548 12.2258 0.266882 12.5975 0.621131L15.3775 3.40247C15.5601 3.58501 15.705 3.80174 15.8038 4.04028C15.9027 4.27882 15.9535 4.53449 15.9535 4.79269C15.9535 5.05089 15.9027 5.30656 15.8038 5.54509C15.705 5.78363 15.5601 6.00036 15.3775 6.18291Z"
                  fill="#DD2E44"
                />
                <path
                  d="M6.22243 7.9209C6.22243 7.9209 -0.263347 15.568 0.0837642 15.9165C0.430875 16.2649 8.07888 9.77734 8.07888 9.77734L6.22243 7.9209Z"
                  fill="#99AAB5"
                />
                <path
                  d="M11.2064 12.4403C11.0239 12.623 10.8071 12.768 10.5685 12.8669C10.3298 12.9658 10.0741 13.0167 9.81577 13.0167C9.55746 13.0167 9.30169 12.9658 9.06307 12.8669C8.82445 12.768 8.60766 12.623 8.4251 12.4403L3.55932 7.5736C3.20085 7.20268 3.00246 6.70577 3.00691 6.18996C3.01135 5.67414 3.21827 5.18073 3.58308 4.81604C3.94789 4.45135 4.44137 4.24458 4.95719 4.2403C5.473 4.23602 5.96985 4.43457 6.34066 4.79316L11.2064 9.65938C11.389 9.84196 11.5339 10.0587 11.6327 10.2973C11.7316 10.5359 11.7824 10.7916 11.7824 11.0498C11.7824 11.3081 11.7316 11.5638 11.6327 11.8023C11.5339 12.0409 11.389 12.2577 11.2064 12.4403Z"
                  fill="#DD2E44"
                />
              </g>
              <defs>
                <clipPath id="clip0_243_4556">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </dt>
          <dd>
            출출하면 안되죠.<br /> 
            세션 들을 때<br />
            당 충전 필수
          </dd>
        </dl>
        <dl>
          <dt>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M5.73705 13.6666C5.61438 14.6483 4.71882 14.9999 3.73705 14.9999C2.75527 14.9999 2.05882 14.2039 2.18149 13.2221L2.62594 9.22211C2.7486 8.24034 3.64416 7.44434 4.62594 7.44434C5.60771 7.44434 7.5006 7.44434 6.18149 9.22211C4.86238 10.9999 5.73705 13.6666 5.73705 13.6666Z"
                fill="#BE1931"
              />
              <path
                d="M15.922 6.55556C15.922 9.62311 15.2358 12.1111 14.3887 12.1111C13.542 12.1111 5.2998 9.62311 5.2998 6.55556C5.2998 3.488 13.542 1 14.3887 1C15.2358 1 15.922 3.48756 15.922 6.55556Z"
                fill="#CCD6DD"
              />
              <path
                d="M14.3888 12.1111C15.2356 12.1111 15.9221 9.6238 15.9221 6.55556C15.9221 3.48731 15.2356 1 14.3888 1C13.542 1 12.8555 3.48731 12.8555 6.55556C12.8555 9.6238 13.542 12.1111 14.3888 12.1111Z"
                fill="#66757F"
              />
              <path
                d="M7.96653 9.66656L1.7443 9.22211C-0.477919 9.22211 -0.477919 3.88878 1.7443 3.88878L7.96653 3.44434C6.63319 4.77767 6.63319 8.33323 7.96653 9.66656Z"
                fill="#DD2E44"
              />
              <path
                d="M13.922 8.48794C14.2902 8.48794 14.5887 7.62275 14.5887 6.55549C14.5887 5.48823 14.2902 4.62305 13.922 4.62305C13.5538 4.62305 13.2554 5.48823 13.2554 6.55549C13.2554 7.62275 13.5538 8.48794 13.922 8.48794Z"
                fill="#99AAB5"
              />
            </svg>
          </dt>
          <dd>
            업계 동료들과<br />
            즐거운 네트워킹<br />
            시간을 가지세요!
          </dd>
        </dl>
      </div>
    </section>
  );
};

export default Event;
