import React, { useEffect, useRef, useState } from 'react';
import'./SponsorShip.css';
import backgroundImg from '../../assets/Subtract2.png';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

const emailSchema = z.string().email();

const SponsorShip = () => {

    const userRef = useRef<HTMLInputElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);
    const phoneRef = useRef<HTMLInputElement>(null);
    const agreementRef = useRef<HTMLInputElement>(null);
  
    const [user, setUser] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [checkList, setCheckList] = useState<string[]>([]);
    const [agreementBtn, setagreementBtn] = useState<boolean>(true);
    const [donation, setDonation] = useState<string>("");

    const [company, setCompany] = useState<string>("")
    const [title, setTitle] = useState<string>("")
  
    const [userError, setUserError] = useState<string | null>(null);
    const [emailError, setEmailError] = useState<string | null>(null);
    const [phoneError, setPhoneError] = useState<string | null>(null);
    const [userInputBorder, setUserInputBorder] = useState(false);
    const [emailInputBorder, setEmailInputBorder] = useState(false);
    const [phoneInputBorder, setPhoneInputBorder] = useState(false);
  
  
    const navigate = useNavigate();
  

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
    // 유효성 체크 항목
    // 진행중(2024.08.22일)
    const nameRegex = /[!@#$%^&*()_+{}\[\]:;<>?\/\\|~]/; // 특수 문자가 들어있다면 '경고'띄우기
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // 이메일 형식으로 작성하지 않을경우 '경고' 띄우기 예시 abc@abcd.com
    const phoneRegex = /^(\+[1-9]{1,3})?[ \-_.]?([2-9]|[1-9]\d{2}|0[1-9]0|[1-9]\d{3})[ \-_.]?([1-9]\d{2,3})[ \-_.]?([1-9]\d{3})?$/;
    
  
  
    // 사용자 유효한 warning function
    const validateUser = (value: string) => {
      if (nameRegex.test(value)) {
        setUserError("입력 조건(영어/한글 2글자 이상, 특수문자 제외)에 맞지 않습니다.");
        return true
      } else {
        setUserError(null);
        return false
      }
    };
    // email 유효한 warning function
    const validateEmail = (value: string) => {
      if (!emailRegex.test(value) || !emailSchema.safeParse(email).success ) {
        setEmailError("이메일 형식에 맞지 않습니다.");
        return true
      } else {
        setEmailError(null);
        return false
      }
    };
    // phone 유효한 warning function
    const validatePhone = (value: string) => {
      if (!phoneRegex.test(value)) {
        setPhoneError("전화번호 형식에 맞지 않습니다.");
        return true
      } else {
        setPhoneError(null);
        return false
      }
    };
  
    // 개별 동의 체크 박스 function
    const check = (e: React.ChangeEvent<HTMLInputElement>) => {
      e.target.checked
        ? setCheckList([...checkList, e.target.name])
        : setCheckList(checkList.filter((item) => item !== e.target.name));
    };
  
    // 전체 동의 체크 박스 function
    const checkAll = (e: React.ChangeEvent<HTMLInputElement>) => {
      e.target.checked
        ? setCheckList(["age", "term", "personalInfo", "thirdParty"])
        : setCheckList([]);
    };
  
  
  
  
    // submit function
    const handleSumbit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const form = e.target as HTMLFormElement;
  
      const data = new FormData(form);
      const formDataObject = Object.fromEntries(data.entries());
  
      //1차 데이터 정제
      const cleanedData = Object.fromEntries(
        Object.entries(formDataObject).map(([key, value]) => {
          if (typeof value === "string") {
            // 앞뒤 공백 제거
            let cleanedValue = value.trim();
  
            // 핸드폰 '-' 제거
            if (key === "phone") {
              cleanedValue = cleanedValue.replace(/-/g, "");
            }
  
            return [key, cleanedValue];
          }
  
          return [key, value];
        })
      );

  
      if (cleanedData.user.length < 2 || validateUser(cleanedData.user)) {
        setUserInputBorder(true);
        setUserError("입력 조건(영어/한글 2글자 이상, 특수문자 제외)에 맞지 않습니다.");
        userRef.current?.scrollIntoView({ 
          behavior: "smooth",
          block:"center"
          });
        return;
      }
  
  
      if(validateEmail(cleanedData.email)){
        setEmailInputBorder(true);
        setEmailError("이메일 형식에 맞지 않습니다.");
        emailRef.current?.scrollIntoView({
          behavior : "smooth",
          block : "center",
        })
        return
      }
  
      if(validatePhone(cleanedData.phone) || cleanedData.phone.length !== 11){
        setPhoneInputBorder(true);
        setPhoneError("전화번호 형식에 맞지 않습니다.")
        phoneRef.current?.scrollIntoView({
          behavior: "smooth",
          block : "center",
        });
        
        return
      }

      if (checkList.length !== 4) {
        setagreementBtn(false);
        agreementRef.current?.scrollIntoView({
          behavior:"smooth",
          block: "center",
        })
        setagreementBtn(false);
        return;
      } else {
        setagreementBtn(true);
      }
  
      // Submit Fetch logic 
      const donationInt = parseInt(cleanedData.donation)
      const submitData = {
        method : 'POST',
        headers : {
          'Content-Type': 'application/json',
        },
        body : JSON.stringify({
          name: cleanedData.user,
          phone: cleanedData.phone,
          email : cleanedData.email,
          company : cleanedData.company,
          position: cleanedData.position,
          category: cleanedData.sponsorshipPurpose,
          amount: donationInt
        })
      }
  
  
      try{
        const userName = cleanedData.user;
        const response = await fetch('https://conference.puddingcamp.com/api/donations',submitData);
  
        if(!response.ok){
          if(response.status === 400){
            throw new Error(`입력 정보가 올바르지 않습니다.`);
          }

          if(response.status === 500){
            navigate('/errors/500');
            return
          }

          throw new Error("다시 한번 입력 폼을 확인 부탁드립니다.");
        }
        const data = await response.json();

        if(data.success === true){        
        // const encodedMessage = encodeURIComponent(data.message);encodedUser
        const encodedUser = encodeURIComponent(userName);
      
        // Construct the URL with query parameters
        const url = `/sponsorship/success?message=success&name=${encodedUser}`;
        
        // Redirect to the new URL
        navigate(url);
      }
  
      } catch(e){ 
        window.alert(`진행중 오류가 발생했습니다.\n${e}`);
      }
  
    };
  

    // 한글 '을/를' 처리 function
    const  checkName = (name:string):string => {
      //name의 마지막 음절의 유니코드(UTF-16) 
      const charCode = name.charCodeAt(name.length - 1);
      
      //유니코드의 한글 범위 내에서 해당 코드의 받침 확인
      const consonantCode = (charCode - 44032) % 28;
      
      if(consonantCode === 0){
          //0이면 받침 없음 -> 를
          return `${name}를`;
      }
      //1이상이면 받침 있음 -> 을
      return `${name}을`;
  }
  
    
  
    return (
      <main>
        <section className="main-section sponsorship-slogan-wrap">
          <div className="sponsorship-sucess-top">
            <h2 className='page-title-header' >후원 신청 페이지</h2>
            <div
              className="sponsorship-slogan-svg-wrap"
            >
              <svg
                width="307"
                height="69"
                viewBox="0 0 307 69"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_77_3065)">
                  <path
                    d="M48.4305 0C51.2331 0 54.1501 0.722857 55.723 1.64286V12.1571C54.0071 11.1714 51.8337 10.35 49.5458 10.35C46.0568 10.35 44.1694 12.2886 44.1694 14.9171C44.1694 17.5457 46.0568 19.4514 49.5458 19.4514C51.8337 19.4514 54.1501 18.6629 55.866 17.5457V27.6C54.2645 28.5857 51.2617 29.3743 48.3161 29.3743C40.0226 29.3743 33.9598 23.1314 33.9598 14.6871C33.9598 6.24286 39.994 0 48.4305 0Z"
                    fill="#F5F5F5"
                  />
                  <path
                    d="M71.6522 0C79.4309 0 85.5223 6.40714 85.5223 14.6871C85.5223 22.9671 79.4595 29.3743 71.6522 29.3743C63.8449 29.3743 57.7821 22.9671 57.7821 14.6871C57.7821 6.40714 63.8735 0 71.6522 0ZM71.6522 19.32C73.7685 19.32 75.4843 17.3486 75.4843 14.9171C75.4843 12.4857 73.7685 10.5143 71.6522 10.5143C69.5359 10.5143 67.82 12.4857 67.82 14.9171C67.82 17.3486 69.5359 19.32 71.6522 19.32Z"
                    fill="#F5F5F5"
                  />
                  <path
                    d="M88.0103 0.427139H97.3046L104.597 12.6171H104.654V0.427139H113.834V28.9471H104.54L97.2475 16.7571H97.1903V28.9471H88.0103V0.427139Z"
                    fill="#F5F5F5"
                  />
                  <path
                    d="M116.894 0.427139H135.283V9.95571H127.133V11.5657H133.653V18.7286H127.133V28.9471H116.923V0.427139H116.894Z"
                    fill="#F5F5F5"
                  />
                  <path
                    d="M137.971 0.427139H155.845V9.95571H148.209V11.5H154.387V18.2686H148.209V19.8786H156.331V28.9471H137.971V0.427139Z"
                    fill="#F5F5F5"
                  />
                  <path
                    d="M158.876 0.427139H169.057C174.176 0.427139 181.126 1.87285 181.126 9.49571C181.126 12.5843 179.867 14.7857 178.009 16.2643L183.671 28.9471H172.947L169.229 19.5829H169.086V28.9471H158.876V0.427139ZM169.858 13.0443C171.46 13.0443 172.203 12.0586 172.203 10.8429C172.203 9.62714 171.46 8.67428 169.858 8.67428H169.115V13.0443H169.858Z"
                    fill="#F5F5F5"
                  />
                  <path
                    d="M184.758 0.427139H202.632V9.95571H194.996V11.5H201.173V18.2686H194.996V19.8786H203.118V28.9471H184.758V0.427139Z"
                    fill="#F5F5F5"
                  />
                  <path
                    d="M205.634 0.427139H214.929L222.221 12.6171H222.279V0.427139H231.459V28.9471H222.164L214.872 16.7571H214.814V28.9471H205.634V0.427139Z"
                    fill="#F5F5F5"
                  />
                  <path
                    d="M248.474 0C251.277 0 254.194 0.722857 255.767 1.64286V12.1571C254.051 11.1714 251.906 10.35 249.59 10.35C246.101 10.35 244.213 12.2886 244.213 14.9171C244.213 17.5457 246.101 19.4514 249.59 19.4514C251.878 19.4514 254.194 18.6629 255.91 17.5457V27.6C254.308 28.5857 251.306 29.3743 248.36 29.3743C240.066 29.3743 233.975 23.1314 233.975 14.6871C233.975 6.24286 240.009 0 248.446 0L248.474 0Z"
                    fill="#F5F5F5"
                  />
                  <path
                    d="M258.741 0.427139H276.615V9.95571H268.979V11.5H275.156V18.2686H268.979V19.8786H277.101V28.9471H258.741V0.427139Z"
                    fill="#F5F5F5"
                  />
                  <path
                    d="M3.38841 57.5986L0.5 42.3857L10.4807 39.8886L13.2262 54.3129C13.7123 56.9414 14.7991 58.5843 17.1155 58.0257C19.432 57.4671 19.8037 55.66 19.2604 52.8671L16.515 38.3771L25.4948 36.1429L28.3546 51.1914C29.1553 55.4629 28.7264 58.3871 27.7826 60.49C26.1525 64.1371 22.4062 66.5686 18.288 67.5871C14.1699 68.6057 9.99458 68.0471 7.30636 65.6814C5.79065 64.3671 4.24636 62.1329 3.38841 57.5986Z"
                    fill="#1A8DF7"
                  />
                  <path
                    d="M35.3039 36.1429L44.5125 37.5886L50.3179 50.7971H50.4037L51.8336 38.7386L60.9278 40.1514L57.5818 68.4086L48.3732 66.9629L42.5678 53.7543H42.482L41.0521 65.8129L31.9579 64.4L35.3039 36.1429Z"
                    fill="#68B7FF"
                  />
                  <path
                    d="M77.1436 38.5087C79.9462 38.5087 82.8633 39.2315 84.4362 40.1515V50.6658C82.7203 49.6801 80.5468 48.8587 78.259 48.8587C74.77 48.8587 72.8825 50.7973 72.8825 53.4258C72.8825 56.0544 74.77 57.9601 78.259 57.9601C80.5468 57.9601 82.8633 57.1715 84.5791 56.0544V66.1087C82.9776 67.0944 79.9748 67.883 77.0292 67.883C68.7358 67.883 62.673 61.6401 62.673 53.1958C62.673 44.7515 68.7072 38.5087 77.1436 38.5087Z"
                    fill="#F5F5F5"
                  />
                  <path
                    d="M100.394 38.5087C108.172 38.5087 114.264 44.9158 114.264 53.1958C114.264 61.4758 108.201 67.883 100.394 67.883C92.5862 67.883 86.5234 61.443 86.5234 53.1958C86.5234 44.9487 92.5862 38.5087 100.394 38.5087ZM100.394 57.8287C102.51 57.8287 104.226 55.8573 104.226 53.4258C104.226 50.9944 102.51 49.023 100.394 49.023C98.2773 49.023 96.5614 50.9944 96.5614 53.4258C96.5614 55.8573 98.2773 57.8287 100.394 57.8287Z"
                    fill="#F5F5F5"
                  />
                  <path
                    d="M116.723 38.9358H126.018L133.31 51.1258H133.367V38.9358H142.547V67.4558H133.253L125.96 55.2658H125.903V67.4558H116.723V38.9358Z"
                    fill="#F5F5F5"
                  />
                  <path
                    d="M145.635 38.9358H164.024V48.4644H155.874V50.0744H162.394V57.2373H155.874V67.4558H145.664V38.9358H145.635Z"
                    fill="#F5F5F5"
                  />
                  <path
                    d="M166.683 38.9358H184.557V48.4644H176.921V50.0087H183.099V56.7773H176.921V58.3873H185.043V67.4558H166.683V38.9358Z"
                    fill="#F5F5F5"
                  />
                  <path
                    d="M187.589 38.9358H197.77C202.889 38.9358 209.838 40.3816 209.838 48.0044C209.838 51.093 208.58 53.2944 206.721 54.773L212.383 67.4558H201.659L197.97 58.0916H197.827V67.4558H187.617V38.9358H187.589ZM198.57 51.553C200.172 51.553 200.915 50.5673 200.915 49.3516C200.915 48.1358 200.172 47.183 198.57 47.183H197.827V51.553H198.57Z"
                    fill="#F5F5F5"
                  />
                  <path
                    d="M213.471 38.9358H231.345V48.4644H223.709V50.0087H229.886V56.7773H223.709V58.3873H231.831V67.4558H213.471V38.9358Z"
                    fill="#F5F5F5"
                  />
                  <path
                    d="M234.375 38.9358H243.669L250.962 51.1258H251.019V38.9358H260.199V67.4558H250.905L243.612 55.2658H243.555V67.4558H234.375V38.9358Z"
                    fill="#F5F5F5"
                  />
                  <path
                    d="M277.187 38.5087C279.99 38.5087 282.907 39.2315 284.48 40.1515V50.6658C282.764 49.6801 280.619 48.8587 278.303 48.8587C274.814 48.8587 272.926 50.7973 272.926 53.4258C272.926 56.0544 274.814 57.9601 278.303 57.9601C280.591 57.9601 282.907 57.1715 284.623 56.0544V66.1087C283.021 67.0944 280.019 67.883 277.073 67.883C268.78 67.883 262.688 61.6401 262.688 53.1958C262.688 44.7515 268.722 38.5087 277.159 38.5087H277.187Z"
                    fill="#F5F5F5"
                  />
                  <path
                    d="M287.453 38.9358H305.327V48.4644H297.691V50.0087H303.868V56.7773H297.691V58.3873H305.813V67.4558H287.453V38.9358Z"
                    fill="#F5F5F5"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_77_3065">
                    <rect
                      width="306"
                      height="69"
                      fill="white"
                      transform="translate(0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <p
            className='sponsor-slogan-explain'
          >
          푸딩캠프에서 주최하는 '학습과 성장 2024' 컨퍼런스를<br />
          함께 만들어 갈 <span className='sponsor-slogan-company-name' >{checkName(user || '후원사')}</span> 모십니다.
          </p>

        </section>
  
        {/* form 영역 */}
  
        <section className="payment-form-wrap">
  
          <form 
            onSubmit={handleSumbit} 
            className="payment-form">
            <div className="payment-input-wrap">
              {/* name input 영역 */}
              <div className="sponsorship-form-inputs">
                <label htmlFor="user" className="sponsorship-each-title">
                  이름
                </label>
                <input
                  className={`${userError ? "payment-user-input" :""}
                    ${userInputBorder ? "payment-user-input-border" : ""}`}
                  ref={userRef}
                  type="text"
                  name="user"
                  minLength={2}
                  placeholder="홍길동"
                  id="user"
                  value={user}
                  onChange={(e) => {
                    setUser(e.target.value);
                    validateUser(e.target.value);
                  }}
                  onBlur={() => validateUser(user)}
                />
                {userError && (
                  <p className="payment-input-warning">
                    <span>
                      <svg
                        width="12"
                        height="11"
                        viewBox="0 0 12 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6 4H6.005M5.5 5.5H6V7.5H6.5M1.5 5.5C1.5 6.09095 1.6164 6.67611 1.84254 7.22208C2.06869 7.76804 2.40016 8.26412 2.81802 8.68198C3.23588 9.09984 3.73196 9.43131 4.27792 9.65746C4.82389 9.8836 5.40905 10 6 10C6.59095 10 7.17611 9.8836 7.72208 9.65746C8.26804 9.43131 8.76412 9.09984 9.18198 8.68198C9.59984 8.26412 9.93131 7.76804 10.1575 7.22208C10.3836 6.67611 10.5 6.09095 10.5 5.5C10.5 4.30653 10.0259 3.16193 9.18198 2.31802C8.33807 1.47411 7.19347 1 6 1C4.80653 1 3.66193 1.47411 2.81802 2.31802C1.97411 3.16193 1.5 4.30653 1.5 5.5Z"
                          stroke="#FF4242"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                    {userError}
                  </p>
                )}
              </div>
  
              {/* email input 영역 */}
              <div className="sponsorship-form-inputs">
                <label htmlFor="email" className="sponsorship-each-title">
                  이메일
                </label>
                <input
                  className={`${emailError ? "payment-email-input" : ""}
                  ${emailInputBorder ? "payment-email-input-border" : ""}`}
  
                  ref={emailRef}
                  type="text"
                  name="email"
                  placeholder="abc@realworldpudding.com"
                  id="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    validateEmail(e.target.value);
                  }}
                  onBlur={() => validateEmail(email)}
                />
                {emailError && (
                  <p className="payment-input-warning">
                    <span>
                      <svg
                        width="12"
                        height="11"
                        viewBox="0 0 12 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6 4H6.005M5.5 5.5H6V7.5H6.5M1.5 5.5C1.5 6.09095 1.6164 6.67611 1.84254 7.22208C2.06869 7.76804 2.40016 8.26412 2.81802 8.68198C3.23588 9.09984 3.73196 9.43131 4.27792 9.65746C4.82389 9.8836 5.40905 10 6 10C6.59095 10 7.17611 9.8836 7.72208 9.65746C8.26804 9.43131 8.76412 9.09984 9.18198 8.68198C9.59984 8.26412 9.93131 7.76804 10.1575 7.22208C10.3836 6.67611 10.5 6.09095 10.5 5.5C10.5 4.30653 10.0259 3.16193 9.18198 2.31802C8.33807 1.47411 7.19347 1 6 1C4.80653 1 3.66193 1.47411 2.81802 2.31802C1.97411 3.16193 1.5 4.30653 1.5 5.5Z"
                          stroke="#FF4242"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                    {emailError}
                  </p>
                )}


              </div>
  
              {/* phone input 영역 */}
              <div className="sponsorship-form-inputs">
                <label htmlFor="phone" className="sponsorship-each-title">
                  전화번호
                </label>
                
                <input
                  className={`${phoneError ? "payment-phone-input" : ""}
                  ${phoneInputBorder ? "payment-phone-input-border" : ""}`}
  
                  ref={phoneRef}
                  type="text"
                  name="phone"
                  placeholder="01012345678"
                  id="phone"
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                    validatePhone(e.target.value);
                  }}
                  onBlur={() => validatePhone(phone)}
                />
                {phoneError && (
                  <p className="payment-input-warning">
                    <span>
                      <svg
                        width="12"
                        height="11"
                        viewBox="0 0 12 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6 4H6.005M5.5 5.5H6V7.5H6.5M1.5 5.5C1.5 6.09095 1.6164 6.67611 1.84254 7.22208C2.06869 7.76804 2.40016 8.26412 2.81802 8.68198C3.23588 9.09984 3.73196 9.43131 4.27792 9.65746C4.82389 9.8836 5.40905 10 6 10C6.59095 10 7.17611 9.8836 7.72208 9.65746C8.26804 9.43131 8.76412 9.09984 9.18198 8.68198C9.59984 8.26412 9.93131 7.76804 10.1575 7.22208C10.3836 6.67611 10.5 6.09095 10.5 5.5C10.5 4.30653 10.0259 3.16193 9.18198 2.31802C8.33807 1.47411 7.19347 1 6 1C4.80653 1 3.66193 1.47411 2.81802 2.31802C1.97411 3.16193 1.5 4.30653 1.5 5.5Z"
                          stroke="#FF4242"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                    {phoneError}
                  </p>
                )}
              </div>

              {/* 회사명 input 영역 */}
              <div className="sponsorship-form-inputs">
                <label htmlFor="phone" className="sponsorship-each-title">
                  회사명 (선택)
                </label>
                <input
                  className=""
                  type="text"
                  name="company"
                  placeholder="리얼 월드 푸딩 캠프 컴퍼니"
                  id="phone"
                  value={company}
                  onChange={(e) => {
                    setCompany(e.target.value);
                  }}
                />
              </div>

              {/* 직함 input 영역 */}
              <div className="sponsorship-form-inputs">
                <label htmlFor="title" className="sponsorship-each-title">
                  직함 (선택)
                </label>
                <input
                  className=""
                  type="text"
                  name="title"
                  placeholder="대리"
                  id="title"
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                />
              </div>
            </div>
  
  
            <div className="payment-border"></div>
  
  
            {/* 후원 목적 영역 */}
            <fieldset className="sponsorship-purpose-wrap">
              <p
                className='sponsorship-each-title'
              >
                후원 목적
              </p>
              <div
                className='sponsorship-classified-types'
              >
              <div
                  className='sponsorship-radio-wrap'
                >
                  <input 
                    type="radio"
                    id='recruiting'
                    name='sponsorshipPurpose'
                    value="recruiting"
                  />
                  <label htmlFor="recruiting">리크루팅</label>
                </div>

                <div
                  className='sponsorship-radio-wrap'
                >
                  <input 
                    type="radio" 
                    id='brand'
                    name='sponsorshipPurpose'
                    value='branding'
                    />
                  <label htmlFor="brand">브랜드(홍보)</label>
                </div>

                <div
                className='sponsorship-radio-wrap'
                >
                  <input 
                    type="radio" 
                    id='personalSponsor'
                    name='sponsorshipPurpose'
                    value='personal'
                    defaultChecked
                  />
                  <label htmlFor="personalSponsor">개인후원</label>
                </div>

                <div
                className='sponsorship-radio-wrap'
                >
                  <input 
                  type="radio" 
                  id='etc'
                  name='sponsorshipPurpose'
                  value='other'
                  />
                  <label htmlFor="etc">기타</label>
                </div>

              </div>
                
            </fieldset>
  
            <div className="payment-border"></div>
  
            {/* 후원금  영역  */}
            <div
              ref={agreementRef}
              className='sponsorship-money-input-wrap'
            >
              <label htmlFor="donation">예상 후원금액 (선택)</label>
              <input
                id='donation'
                name='donation'
                type="text"
                placeholder='50,000원'
                value={donation}
                onChange={(e)=>setDonation(e.target.value)}
              />
              </div>

              <div className="payment-border"></div>


              {/* 동의 영역  */}
              <div
                ref={agreementRef}
                className={`sponsorship-agreement-wrap ${
                  !agreementBtn ? "agreement-warning" : ""
                }`}
              >
                <div>
                  <input
                    className={` ${
                      !agreementBtn ? "agreement-warning-border" : ""
                    }`}
                    type="checkbox"
                    id="all"
                    name="all"
                    onChange={checkAll}
                    checked={checkList.length === 4 ? true : false}
                  />
                  <label htmlFor="all" >전체 동의</label>
                </div>

                <div>
                  <input
                    className={` ${
                      !agreementBtn ? "agreement-warning-border" : ""
                    }`}
                    type="checkbox"
                    id="age"
                    name="age"
                    onChange={check}
                    checked={checkList.includes("age") ? true : false}
                  />
                  <label htmlFor="age">(필수) 만 14세 이상입니다.</label>
                </div>

                <div>
                  <input
                    className={` ${
                      !agreementBtn ? "agreement-warning-border" : ""
                    }`}
                    type="checkbox"
                    id="personalInfo"
                    name="personalInfo"
                    onChange={check}
                    checked={checkList.includes("personalInfo") ? true : false}
                  />
                  <label htmlFor="personalInfo">
                    (필수) 개인정보 수집 이용 동의서
                  </label>
                </div>
                <div>
                  <input
                    className={` ${
                      !agreementBtn ? "agreement-warning-border" : ""
                    }`}
                    type="checkbox"
                    id="term"
                    name="term"
                    onChange={check}
                    checked={checkList.includes("term") ? true : false}
                  />
                  <label htmlFor="term">(필수) 이용 약관 동의</label>
                </div>
                <div>
                  <input
                    className={` ${
                      !agreementBtn ? "agreement-warning-border" : ""
                    }`}
                    type="checkbox"
                    id="thirdParty"
                    name="thirdParty"
                    onChange={check}
                    checked={checkList.includes("thirdParty") ? true : false}
                  />
                  <label htmlFor="thirdParty">(필수) 제 3자 제공 동의서</label>
                </div>
              </div>
  
            <button type="submit">후원하기</button>

          </form>
        </section>

  
        <img className="background-img" src={backgroundImg} alt="배경이미지" />
      </main>
    );
  };

export default SponsorShip