import { useEffect, useState } from "react";
import backgroundImg from '../../assets/Subtract2.png';
import './Schedule.css';

const Schedule = () => {
  const [tab, setTab] =  useState(0);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  

  return (
    <main>
      <section className="main-section schedule-slogan-wrap">
        <div className="schedule-success-top">
          <h2 className="page-title-header" >행사일정 페이지</h2>
          <div className="payment-slogan-svg-wrap">
            <svg
              width="307"
              height="69"
              viewBox="0 0 307 69"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_77_3065)">
                <path
                  d="M48.4305 0C51.2331 0 54.1501 0.722857 55.723 1.64286V12.1571C54.0071 11.1714 51.8337 10.35 49.5458 10.35C46.0568 10.35 44.1694 12.2886 44.1694 14.9171C44.1694 17.5457 46.0568 19.4514 49.5458 19.4514C51.8337 19.4514 54.1501 18.6629 55.866 17.5457V27.6C54.2645 28.5857 51.2617 29.3743 48.3161 29.3743C40.0226 29.3743 33.9598 23.1314 33.9598 14.6871C33.9598 6.24286 39.994 0 48.4305 0Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M71.6522 0C79.4309 0 85.5223 6.40714 85.5223 14.6871C85.5223 22.9671 79.4595 29.3743 71.6522 29.3743C63.8449 29.3743 57.7821 22.9671 57.7821 14.6871C57.7821 6.40714 63.8735 0 71.6522 0ZM71.6522 19.32C73.7685 19.32 75.4843 17.3486 75.4843 14.9171C75.4843 12.4857 73.7685 10.5143 71.6522 10.5143C69.5359 10.5143 67.82 12.4857 67.82 14.9171C67.82 17.3486 69.5359 19.32 71.6522 19.32Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M88.0103 0.427139H97.3046L104.597 12.6171H104.654V0.427139H113.834V28.9471H104.54L97.2475 16.7571H97.1903V28.9471H88.0103V0.427139Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M116.894 0.427139H135.283V9.95571H127.133V11.5657H133.653V18.7286H127.133V28.9471H116.923V0.427139H116.894Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M137.971 0.427139H155.845V9.95571H148.209V11.5H154.387V18.2686H148.209V19.8786H156.331V28.9471H137.971V0.427139Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M158.876 0.427139H169.057C174.176 0.427139 181.126 1.87285 181.126 9.49571C181.126 12.5843 179.867 14.7857 178.009 16.2643L183.671 28.9471H172.947L169.229 19.5829H169.086V28.9471H158.876V0.427139ZM169.858 13.0443C171.46 13.0443 172.203 12.0586 172.203 10.8429C172.203 9.62714 171.46 8.67428 169.858 8.67428H169.115V13.0443H169.858Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M184.758 0.427139H202.632V9.95571H194.996V11.5H201.173V18.2686H194.996V19.8786H203.118V28.9471H184.758V0.427139Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M205.634 0.427139H214.929L222.221 12.6171H222.279V0.427139H231.459V28.9471H222.164L214.872 16.7571H214.814V28.9471H205.634V0.427139Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M248.474 0C251.277 0 254.194 0.722857 255.767 1.64286V12.1571C254.051 11.1714 251.906 10.35 249.59 10.35C246.101 10.35 244.213 12.2886 244.213 14.9171C244.213 17.5457 246.101 19.4514 249.59 19.4514C251.878 19.4514 254.194 18.6629 255.91 17.5457V27.6C254.308 28.5857 251.306 29.3743 248.36 29.3743C240.066 29.3743 233.975 23.1314 233.975 14.6871C233.975 6.24286 240.009 0 248.446 0L248.474 0Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M258.741 0.427139H276.615V9.95571H268.979V11.5H275.156V18.2686H268.979V19.8786H277.101V28.9471H258.741V0.427139Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M3.38841 57.5986L0.5 42.3857L10.4807 39.8886L13.2262 54.3129C13.7123 56.9414 14.7991 58.5843 17.1155 58.0257C19.432 57.4671 19.8037 55.66 19.2604 52.8671L16.515 38.3771L25.4948 36.1429L28.3546 51.1914C29.1553 55.4629 28.7264 58.3871 27.7826 60.49C26.1525 64.1371 22.4062 66.5686 18.288 67.5871C14.1699 68.6057 9.99458 68.0471 7.30636 65.6814C5.79065 64.3671 4.24636 62.1329 3.38841 57.5986Z"
                  fill="#1A8DF7"
                />
                <path
                  d="M35.3039 36.1429L44.5125 37.5886L50.3179 50.7971H50.4037L51.8336 38.7386L60.9278 40.1514L57.5818 68.4086L48.3732 66.9629L42.5678 53.7543H42.482L41.0521 65.8129L31.9579 64.4L35.3039 36.1429Z"
                  fill="#68B7FF"
                />
                <path
                  d="M77.1436 38.5087C79.9462 38.5087 82.8633 39.2315 84.4362 40.1515V50.6658C82.7203 49.6801 80.5468 48.8587 78.259 48.8587C74.77 48.8587 72.8825 50.7973 72.8825 53.4258C72.8825 56.0544 74.77 57.9601 78.259 57.9601C80.5468 57.9601 82.8633 57.1715 84.5791 56.0544V66.1087C82.9776 67.0944 79.9748 67.883 77.0292 67.883C68.7358 67.883 62.673 61.6401 62.673 53.1958C62.673 44.7515 68.7072 38.5087 77.1436 38.5087Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M100.394 38.5087C108.172 38.5087 114.264 44.9158 114.264 53.1958C114.264 61.4758 108.201 67.883 100.394 67.883C92.5862 67.883 86.5234 61.443 86.5234 53.1958C86.5234 44.9487 92.5862 38.5087 100.394 38.5087ZM100.394 57.8287C102.51 57.8287 104.226 55.8573 104.226 53.4258C104.226 50.9944 102.51 49.023 100.394 49.023C98.2773 49.023 96.5614 50.9944 96.5614 53.4258C96.5614 55.8573 98.2773 57.8287 100.394 57.8287Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M116.723 38.9358H126.018L133.31 51.1258H133.367V38.9358H142.547V67.4558H133.253L125.96 55.2658H125.903V67.4558H116.723V38.9358Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M145.635 38.9358H164.024V48.4644H155.874V50.0744H162.394V57.2373H155.874V67.4558H145.664V38.9358H145.635Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M166.683 38.9358H184.557V48.4644H176.921V50.0087H183.099V56.7773H176.921V58.3873H185.043V67.4558H166.683V38.9358Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M187.589 38.9358H197.77C202.889 38.9358 209.838 40.3816 209.838 48.0044C209.838 51.093 208.58 53.2944 206.721 54.773L212.383 67.4558H201.659L197.97 58.0916H197.827V67.4558H187.617V38.9358H187.589ZM198.57 51.553C200.172 51.553 200.915 50.5673 200.915 49.3516C200.915 48.1358 200.172 47.183 198.57 47.183H197.827V51.553H198.57Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M213.471 38.9358H231.345V48.4644H223.709V50.0087H229.886V56.7773H223.709V58.3873H231.831V67.4558H213.471V38.9358Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M234.375 38.9358H243.669L250.962 51.1258H251.019V38.9358H260.199V67.4558H250.905L243.612 55.2658H243.555V67.4558H234.375V38.9358Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M277.187 38.5087C279.99 38.5087 282.907 39.2315 284.48 40.1515V50.6658C282.764 49.6801 280.619 48.8587 278.303 48.8587C274.814 48.8587 272.926 50.7973 272.926 53.4258C272.926 56.0544 274.814 57.9601 278.303 57.9601C280.591 57.9601 282.907 57.1715 284.623 56.0544V66.1087C283.021 67.0944 280.019 67.883 277.073 67.883C268.78 67.883 262.688 61.6401 262.688 53.1958C262.688 44.7515 268.722 38.5087 277.159 38.5087H277.187Z"
                  fill="#F5F5F5"
                />
                <path
                  d="M287.453 38.9358H305.327V48.4644H297.691V50.0087H303.868V56.7773H297.691V58.3873H305.813V67.4558H287.453V38.9358Z"
                  fill="#F5F5F5"
                />
              </g>
              <defs>
                <clipPath id="clip0_77_3065">
                  <rect
                    width="306"
                    height="69"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      </section>

      {/* contents 영역 */}
      <article className="schedule-timetable-wrap">
        <div
          className='schedule-head'
        >
            <button
            className={`schedule-toggle-btn ${tab === 0 ? 'schedule-toggle-btn-activated' : ""}`}
              onClick={()=>setTab(0)}
            >성장홀</button>
            <button
              className={`schedule-toggle-btn ${tab === 1 ? 'schedule-toggle-btn-activated' : ""}`}

              onClick={()=>setTab(1)}
            >도전홀</button>
        </div>

        {/* 성장홀 timetable */}
        <div
          className={`schedule-timetable-body  ${tab===0 ? "schedule-timetable-growth" : ""}`}
        >
          <ul>
            <li>
              <div className="schedule-content-top">12:30 ~ 13:00 (30분)</div>
              <div className="schedule-content-bottom">
                <p>현장등록</p>
              </div>
              <div className="schedule-content-top">13:00 ~ 13:10 (10분)</div>
              <div className="schedule-content-bottom">
                <p>오프닝</p>
              </div>
              <div className="schedule-content-top">13:10 ~ 13:50 (40분)</div>
              <div className="schedule-content-bottom">
                <p>커리어 결정의 순간들</p>
                <div
                  className="schedule-name-box"
                >
                  <p>
                    김민혜
                    <span id="schedule-round">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="2"
                        height="2"
                        viewBox="0 0 2 2"
                        fill="none"
                      >
                        <circle
                          cx="1"
                          cy="1"
                          r="1"
                          fill="white"
                          fill-opacity="0.6"
                        />
                      </svg>
                    </span>
                    Popenaga
                  </p>
                  <p>개발팀장, 한빛앤 </p>
                </div>
              </div>
              <div className="schedule-content-top">14:00 ~ 14:40 (40분)</div>
              <div className="schedule-content-bottom">
                <p>주니어 PM을 위한 근거기반의<br />기능/실험 기획서 작성법</p>
                <div 
                  className="schedule-name-box"
                >
                  <p>
                    정성영
                    <span id="schedule-round">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="2"
                        height="2"
                        viewBox="0 0 2 2"
                        fill="none"
                      >
                        <circle
                          cx="1"
                          cy="1"
                          r="1"
                          fill="white"
                          fill-opacity="0.6"
                        />
                      </svg>
                    </span>
                    Paul
                  </p>
                  <p>CEO, 마켓핏랩</p>
                </div>
              </div>
              <div className="schedule-content-top">14:50 ~ 15:30 (40분)</div>
              <div className="schedule-content-bottom">
                <p>사이드 프로젝트가 너를 성장케 하리라<br />
                (<u>예상치 못한</u> 방향으로)</p>
                <div 
                  className="schedule-name-box"
                >
                  <p>
                    조단원
                    <span id="schedule-round">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="2"
                        height="2"
                        viewBox="0 0 2 2"
                        fill="none"
                      >
                        <circle
                          cx="1"
                          cy="1"
                          r="1"
                          fill="white"
                          fill-opacity="0.6"
                        />
                      </svg>
                    </span>
                    Chloé
                  </p>
                  <p>프론트엔드 개발자, 산타파이브</p>
                </div>
              </div>

            </li>

          </ul>

          
            {/* 라이트닝 토크 */}
          <ul>
            <li>
              <div className="schedule-content-top">15:40 ~ 16:00 (20분)</div>
              <div className="schedule-content-bottom">
                <p>라이트닝 토크 세션 1 (9/28 공개 예정)</p>

              </div>
            </li>
            <li>
              <div className="schedule-content-top">16:05 ~ 16:25 (20분)</div>
              <div className="schedule-content-bottom">
                <p>라이트닝 토크 세션 3 (9/28 공개 예정)</p>

              </div>
            </li>

          </ul>


            {/* 네트워킹 */}
          <ul>
            <li>
              <div className="schedule-content-top">16:25 ~ 16:55 (30분)</div>
              <div className="schedule-content-bottom">
                <p>네트워킹 및 간식 시간 (성장홀)</p>
              </div>
            </li>
            <li>
              <div className="schedule-content-top">16:25 ~ 16:55 (30분)</div>
              <div className="schedule-content-bottom">
                <p>클로징 및 기념사진 (성장홀)</p>

              </div>
            </li>
          </ul>
        </div>


        {/* 도전홀 */}
        <div
          className={`schedule-timetable-body  ${tab===1 ? "schedule-timetable-challenge" : ""}`}
        >
          <ul>
            <li>
              <div className="schedule-content-top">12:30 ~ 13:00 (30분)</div>
              <div className="schedule-content-bottom">
                <p>현장등록</p>
              </div>
              <div className="schedule-content-top">13:00 ~ 13:10 (10분)</div>
              <div className="schedule-content-bottom">
                <p>오프닝</p>
              </div>
              <div className="schedule-content-top">13:10 ~ 13:50 (40분)</div>
              <div className="schedule-content-bottom">
                <p>도움을 주고 받으면서 배우고 성장하기</p>
                <div
                  className="schedule-name-box"
                >
                  <p>
                    김태희
                    <span id="schedule-round">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="2"
                        height="2"
                        viewBox="0 0 2 2"
                        fill="none"
                      >
                        <circle
                          cx="1"
                          cy="1"
                          r="1"
                          fill="white"
                          fill-opacity="0.6"
                        />
                      </svg>
                    </span>
                    탐정토끼
                  </p>
                  <p>웹 풀스택 개발자, 셀파스 </p>
                </div>
              </div>
              <div className="schedule-content-top">14:00 ~ 14:40 (40분)</div>
              <div className="schedule-content-bottom">
                <p>개발자, 개발 리더, 그리고 프로덕트 오너로!</p>
                <div 
                  className="schedule-name-box"
                >
                  <p>
                    서지연
                    <span id="schedule-round">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="2"
                        height="2"
                        viewBox="0 0 2 2"
                        fill="none"
                      >
                        <circle
                          cx="1"
                          cy="1"
                          r="1"
                          fill="white"
                          fill-opacity="0.6"
                        />
                      </svg>
                    </span>
                    치즈
                  </p>
                  <p>Data AI 팀 리더, 비마이프렌즈</p>
                </div>
              </div>
              <div className="schedule-content-top">14:50 ~ 15:30 (40분)</div>
              <div className="schedule-content-bottom">
                <p>입문하는 PM, 성장하는 PM</p>
                <div 
                  className="schedule-name-box"
                >
                  <p>
                    권준호
                    <span id="schedule-round">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="2"
                        height="2"
                        viewBox="0 0 2 2"
                        fill="none"
                      >
                        <circle
                          cx="1"
                          cy="1"
                          r="1"
                          fill="white"
                          fill-opacity="0.6"
                        />
                      </svg>
                    </span>
                    Kwaan
                  </p>
                  <p>대표파트너, PMF파트너스</p>
                </div>
              </div>

            </li>

          </ul>

          
            {/* 라이트닝 토크 */}
          <ul>
            <li>
              <div className="schedule-content-top">15:40 ~ 16:00 (20분)</div>
              <div className="schedule-content-bottom">
                <p>라이트닝 토크 세션 2 (9/28 공개 예정)</p>

              </div>
            </li>
            <li>
              <div className="schedule-content-top">16:05 ~ 16:25 (20분)</div>
              <div className="schedule-content-bottom">
                <p>라이트닝 토크 세션 4 (9/28 공개 예정)</p>

              </div>
            </li>

          </ul>


            {/* 네트워킹 */}
          <ul>
            <li>
              <div className="schedule-content-top">16:25 ~ 16:55 (30분)</div>
              <div className="schedule-content-bottom">
                <p>네트워킹 및 간식 시간 (성장홀)</p>
              </div>
            </li>
            <li>
              <div className="schedule-content-top">16:25 ~ 16:55 (30분)</div>
              <div className="schedule-content-bottom">
                <p>클로징 및 기념사진 (성장홀)</p>

              </div>
            </li>
          </ul>

        </div>

      </article>



    <img className="background-img"  src={backgroundImg} alt="배경이미지" />

    </main>
  );
};

export default Schedule;
