import './App.css';

import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';

import Main from './components/Main/Main';

if (import.meta.env.VITE_GA_ID) {
  ReactGA.initialize(import.meta.env.VITE_GA_ID, { debug: true });
  
  const history = createBrowserHistory();
  history.listen((response) => {
    ReactGA.set({ page: response.location.pathname });
    ReactGA.pageview(response.location.pathname);
  });
}

function App() {
  return (
    <>
      <Main />
    </>
  )
}

export default App
