
const MainFaq = () => {
  return (
    <section className="main-section main-faq-wrap ">
      <p className="main-seaction-title">FAQ</p>
      <p className="main-faq-contents main-section-each-title">
        자주 묻는 질문
        <br />
      </p>

      <div className="main-faq-aco-wrap">
        <details open >
          <summary>
            Q. 라이트닝 토크 세션은 무엇이고, 발제자 신청은 어떻게 할 수 있나요?
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M6 9L12 15L18 9"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </summary>
          <div className="main-faq-list">
          <p>‘학습과 성장 컨퍼런스'는 참가자가 모여 서로 배움과 지식을 나누는 열린 배움의 장입니다. 취지에 맞춰 참가자 여러분이 개발/제품기획/제품디자인/조직운영 등 업을 둘러싼 각자의 경험, 노하우, 질문을 자유롭게 발제하고 공유하는 세션을 직접 진행할 수 있는 ‘라이트닝 토크 세션'을 마련했습니다.</p>
          <ul>
            <li>라이트닝 토크 세션은 세션당 20분씩 진행됩니다. </li>
            <li>발제 희망자는 <span id="underline">contact@puddingcamp.com</span> 으로 1) 성함과 2) 주제를 자유 형식으로 보내주세요.</li>
            <li>세션은 개인 발표, 패널 토론, 참가자 대상 오픈 토론 등 자유로운 방식으로 진행하실 수 있습니다.</li>
            <li>행사 당일 현장에서도 발제 접수를 받습니다. (주제가 준비되신 경우, 행사 당일 운영 스태프에게 알려주세요.)</li>
          </ul>
          </div>
        </details>
        <details >
          <summary>
            Q. 사전 등록은 몇 시부터 가능한가요?
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M6 9L12 15L18 9"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </summary>
          <div className="">
          컨퍼런스 홀 앞에서 오후 12시 30분부터 사전 등록을 시작합니다.<br />
          사전 등록대에서 1) 등록 확인 2) 명찰 발급 후 세션홀에 입장해주세요.
          </div>
        </details>
        <details >
          <summary>
            Q. 신청 정보를 수정할 수 있나요?
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M6 9L12 15L18 9"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </summary>
          <div className="">
          신청자 정보 중 이름과 메일 주소, 전화번호는 수정이 어려워요. 정보를 정확히 기입해주세요.
          </div>
        </details>
        <details >
          <summary>
            Q. 티켓을 구매했는데, 등록이 완료된걸까요?
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M6 9L12 15L18 9"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </summary>
          <div className="">
          구매 후 2영업일 내 참가 등록 메일을 보내드려요.<br />
          이메일을 받지 못하셨다면 contact@puddingcamp.com으로 문의해주세요.
          </div>
        </details>
        <details >
          <summary>
            Q. 행사를 온라인으로 볼 수 있나요?
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M6 9L12 15L18 9"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </summary>
          <div className="">
          네, 온라인 티켓을 등록하신 분에 한하여 라이브 스트리밍을 지원합니다.<br />
          행사 당일 웹사이트에 [세션 보러가기]를 클릭하시면, Youtube LIVE 중계를 시청하실 수 있습니다.
          </div>
        </details>
        <details >
          <summary>
            Q. 다시보기가 제공되나요?
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M6 9L12 15L18 9"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </summary>
          <div className="">
          <p>행사 티켓을 구매하신 분들에게 세션 다시보기를 제공합니다. 행사 종료 후 10월 또는 11월 중에 신청하신 메일 주소로 다시보기 링크를 보내드려요.</p>
          <p><span id="underline">강연 Q&A, 현장 발제 세션, 일부 비공개 세션</span>은 다시보기가 제공되지 않습니다.</p>
          </div>
        </details>
        <details >
          <summary>
            Q. 강연 슬라이드를 공유해주나요?
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M6 9L12 15L18 9"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </summary>
          <div className="">
          <p>네, 강연 슬라이드는 행사 종료 후 10월 또는 11월 중에 신청하신 메일 주소로 세션 다시보기와 함께 강연 슬라이드 다운로드 링크를 보내드려요 :)</p>
          <p>(일부 <span id="underline">비공개 세션</span>은 자료를 제공하지 않습니다.) </p>
          </div>
        </details>
        <details >
          <summary>
            Q. 취소 및 환불 규정이 궁금해요
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M6 9L12 15L18 9"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </summary>
          <div className="">
          환불 신청은 contact@puddingcamp.com으로 부탁드립니다.<br />
          1. 오프라인 티켓은 행사 당일 3일 전까지 전액 환불 신청 가능합니다. (9/25까지 전액 환불, 이후 환불 불가)<br />
          2. 환불 시, 모든 혜택 (다시보기 세션, 자료 제공)은 철회됩니다.<br />
          3. 환불 신청이 완료되면 7영업일 내로 환불합니다. <br />
          </div>
        </details>
        <details >
          <summary>
            Q. 주차할 수 있나요?
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M6 9L12 15L18 9"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </summary>
          <div className="">
          아니요, 주차권을 별도로 제공하지 않습니다. 주변 주차 공간을 이용하거나 대중교통을 이용해주세요.
          </div>
        </details>



      </div>
    </section>
  );
};

export default MainFaq;
